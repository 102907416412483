import { AbilityBuilder } from '@casl/ability'
import userRole from './userRole'

export const abilityAction = {
  READ: 'read',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  VISIT: 'visit',
}

export const abilityComponent = {
  // component
  ACCOUNT: 'ACCOUNT',
  COMPANY_INFORMATION: 'COMPANY_INFORMATION',
  JOB_TITLE: 'JOB_TITLE',
  MANAGE_ALLOCATED_HOURS: 'MANAGE_ALLOCATED_HOURS',
  MANAGE_ACTIVE_ORDER: 'MANAGE_ACTIVE_ORDER',
  MANAGE_MEMBERSHIP: 'MANAGE_MEMBERSHIP',
  MANAGE_STORAGE: 'MANAGE_STORAGE',
  MANAGE_SERVICE: 'MANAGE_SERVICE',
  OPTIONS_BUY_SERVICE: 'OPTIONS_BUY_SERVICE',
  OPTIONS_UPGRADE_MEMBERSHIP: 'OPTIONS_UPGRADE_MEMBERSHIP',
  DROPDOWN_SERVICE_HAS_BUY_ONLY: 'DROPDOWN_SERVICE_HAS_BUY_ONLY',
  CREATE_ORDER: 'CREATE_ORDER',
  BTN_ADD_REVIEW: 'BTN_ADD_REVIEW',
  BTN_REMOVE_REVIEW: 'BTN_REMOVE_REVIEW',
  BTN_ADD_VERSION: 'BTN_ADD_VERSION',
  HOVER_HIDE_UPLOAD_BY: 'HOVER_HIDE_UPLOAD_BY',
  ORDER_FILTER: 'ORDER_FILTER',
  MEMBERSHIP_CUSTOMER: 'MEMBERSHIP_CUSTOMER',
  UPDATE_ORDER_DETAIL: 'UPDATE_ORDER_DETAIL',
  CHANGE_ORDER_STATUS: 'CHANGE_ORDER_STATUS',
  ORDER_LIST_COMPANY_NAME: 'ORDER_LIST_COMPANY_NAME',
  SUBSCRIPTION_INFORMATION_BILLING: 'SUBSCRIPTION_INFORMATION_BILLING',
  ORDER_FILTER_OPERATOR: 'ORDER_FILTER_OPERATOR',
  ORDER_FILTER_CLIENT: 'ORDER_FILTER_CLIENT',
  ORDER_FILTER_CREATOR: 'ORDER_FILTER_CREATOR',
  ORDER_FILTER_MANAGER: 'ORDER_FILTER_MANAGER',
  ORDER_FILTER_CUSTOMER: 'ORDER_FILTER_CUSTOMER',
  INVITE_GUEST_REVIEWER: 'INVITE_GUEST_REVIEWER',
  SUBSCRIPTION_LEAVE: 'SUBSCRIPTION_LEAVE',
  GUEST_REVIEWER_CONTROL: 'GUEST_REVIEWER_CONTROL',
  PAYG_CART: 'PAYG_CART',
  PAYG_TIME: 'PAYG_TIME',

  // navigation
  NAV_PAYMENT: 'NAV_PAYMENT',
  NAV_TEAM_MEMBER: 'NAV_TEAM_MEMBER',
  NAV_CART: 'NAV_CART',
  NAV_MANAGE_ALLOCATED_HOURS: 'NAV_MANAGE_ALLOCATED_HOURS',
  NAV_MANAGE_MEMBERSHIP: 'NAV_MANAGE_MEMBERSHIP',
  NAV_MANAGE_STORAGE: 'NAV_MANAGE_STORAGE',
  NAV_MANAGE_SERVICE: 'NAV_MANAGE_SERVICE',
  NAV_MANAGE_PROFILE: 'NAV_MANAGE_PROFILE',
  NAV_PAYG_CUSTOMER_INFO: 'NAV_PAYG_CUSTOMER_INFO',
  NAV_PEXELS_CUSTOMER_IMAGES: 'NAV_PEXELS_CUSTOMER_IMAGES',
  NAV_PEXELS_ORDER_IMAGES: 'NAV_PEXELS_ORDER_IMAGES',
  NAV_PEXELS_ADD_IMAGES: 'NAV_PEXELS_ADD_IMAGES',
  NAV_RETAIL: 'NAV_RETAIL',
  NAV_USER_REPORTING: 'NAV_USER_REPORTING',

  // page
  PAGE_CREATE_ORDER: 'PAGE_CREATE_ORDER',
  PAGE_PAYMENT: 'PAGE_PAYMENT',
  PAGE_INVOICE: 'PAGE_INVOICE',
  PAGE_TEAM_MEMBER: 'PAGE_TEAM_MEMBER',
  PAGE_MANAGE_ALLOCATED_HOURS: 'PAGE_MANAGE_ALLOCATED_HOURS',
  PAGE_MANAGE_MEMBERSHIP: 'PAGE_MANAGE_MEMBERSHIP',
  PAGE_MANAGE_STORAGE: 'PAGE_MANAGE_STORAGE',
  PAGE_MANAGE_SERVICE: 'PAGE_MANAGE_SERVICE',
  PAGE_CART: 'PAGE_CART',
  PAGE_CHECKOUT_INVOICE: 'PAGE_CHECKOUT_INVOICE',
  PAGE_CHECKOUT: 'PAGE_CHECKOUT',
  PAGE_MANAGE_PROFILE: 'PAGE_MANAGE_PROFILE',
  PAGE_WORKFLOW_EDITOR: 'PAGE_WORKFLOW_EDITOR',

  // order detail select action
  ACTION_PUBLISH_ORDER: 'ACTION_PUBLISH_ORDER',
  ACTION_HOLD_ORDER: 'ACTION_HOLD_ORDER',
  ACTION_CANCEL_ORDER: 'ACTION_CANCEL_ORDER',
  ACTION_REVERT_DRAFT: 'ACTION_REVERT_DRAFT',
  ACTION_DELETE_ORDER: 'ACTION_DELETE_ORDER',
  ACTION_DUPLICATE_ORDER: 'ACTION_DUPLICATE_ORDER',
  ACTION_RESUME_ORDER: 'ACTION_RESUME_ORDER',
  ACTION_RESTORE_ORDER: 'ACTION_RESTORE_ORDER',
  ACTION_ARCHIVE_ORDER: 'ACTION_ARCHIVE_ORDER',

  // order detail play / pause working time
  ACTION_PLAY_WORKING_TIME: 'ACTION_PLAY_WORKING_TIME',
  ACTION_PAUSE_WORKING_TIME: 'ACTION_PAUSE_WORKING_TIME',

  // order detail assigned operator
  ASSIGNED_OPERATOR: 'ASSIGNED_OPERATOR',

  // order detail file attachment
  BTN_ORDER_DETAIL_ATTACH_INTERNAL_REVIEW:
    'BTN_ORDER_DETAIL_ATTACH_INTERNAL_REVIEW',
  BTN_ORDER_DETAIL_ATTACH_CUSTOMER_REVIEW:
    'BTN_ORDER_DETAIL_ATTACH_CUSTOMER_REVIEW',
  // BTN_ORDER_DETAIL_ATTACH_SUPPLIED_FILE: 'BTN_ORDER_DETAIL_ATTACH_SUPPLIED_FILE',
  ACTION_ORDER_DETAIL_DELETE_ATTACHMENT: 'ACTION_ORDER_DETAIL_ATTACHMENT',
  ORDER_DETAIL_INTERNAL_REVIEW_FILE: 'ORDER_DETAIL_INTERNAL_REVIEW_FILE',
  ACTION_ORDER_DETAIL_DELETE_CUSTOMER_REVIEW_ATTACHMENT:
    'ACTION_ORDER_DETAIL_DELETE_CUSTOMER_REVIEW_ATTACHMENT',

  // creative manager actions
  ACTION_CM_APPROVE: 'ACTION_CM_APPROVE',
  REQUEST_CM_REVIEW: 'REQUEST_CM_REVIEW',
  ANNOTATION_PUBLIC_INTERNAL_COMMENT: 'ANNOTATION_PUBLIC_INTERNAL_COMMENT',

  // tour guide
  TOUR_GUIDE: 'TOUR_GUIDE',
}

export const defineAbilitiesFor = (role) => {
  if (role === undefined || role === null) {
    return
  }

  return AbilityBuilder.define((can) => {
    switch (role) {
      case userRole.STAFF:
        // team member role

        //READ
        can(abilityAction.READ, abilityComponent.MANAGE_ALLOCATED_HOURS)
        can(abilityAction.READ, abilityComponent.MANAGE_ACTIVE_ORDER)
        can(abilityAction.READ, abilityComponent.MANAGE_MEMBERSHIP)
        can(abilityAction.READ, abilityComponent.MANAGE_STORAGE)
        can(abilityAction.READ, abilityComponent.MANAGE_SERVICE)
        can(abilityAction.READ, abilityComponent.CREATE_ORDER)
        can(abilityAction.READ, abilityComponent.MEMBERSHIP_CUSTOMER)
        can(abilityAction.READ, abilityComponent.NAV_MANAGE_PROFILE)
        can(abilityAction.READ, abilityComponent.TOUR_GUIDE)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CREATOR)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_OPERATOR)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_MANAGER)
        can(abilityAction.READ, abilityComponent.NAV_PEXELS_CUSTOMER_IMAGES)
        can(abilityAction.READ, abilityComponent.NAV_PEXELS_ADD_IMAGES)
        can(abilityAction.READ, abilityComponent.NAV_PEXELS_ORDER_IMAGES)
        can(abilityAction.READ, abilityComponent.NAV_RETAIL)

        //UPDATE
        can(abilityAction.UPDATE, abilityComponent.CHANGE_ORDER_STATUS)
        can(abilityAction.UPDATE, abilityComponent.ACTION_PUBLISH_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_CANCEL_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_DELETE_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_DUPLICATE_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_HOLD_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_RESUME_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_REVERT_DRAFT)
        can(abilityAction.UPDATE, abilityComponent.ACTION_RESTORE_ORDER)
        can(abilityAction.UPDATE, abilityComponent.NAV_PEXELS_CUSTOMER_IMAGES)
        can(abilityAction.UPDATE, abilityComponent.NAV_PEXELS_ADD_IMAGES)
        can(abilityAction.UPDATE, abilityComponent.NAV_PEXELS_ORDER_IMAGES)

        //VISIT
        can(abilityAction.VISIT, abilityComponent.PAGE_CREATE_ORDER)
        can(abilityAction.VISIT, abilityComponent.PAGE_MANAGE_PROFILE)

        break
      case userRole.OPERATOR:
        // operator role

        // CREATE

        //READ
        can(abilityAction.READ, abilityComponent.BTN_ADD_REVIEW)
        can(abilityAction.READ, abilityComponent.BTN_REMOVE_REVIEW)
        can(abilityAction.READ, abilityComponent.BTN_ADD_VERSION)
        can(abilityAction.READ, abilityComponent.HOVER_HIDE_UPLOAD_BY)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER)
        can(abilityAction.READ, abilityComponent.ORDER_LIST_COMPANY_NAME)
        can(abilityAction.READ, abilityComponent.ACTION_CM_APPROVE)
        can(abilityAction.READ, abilityComponent.REQUEST_CM_REVIEW)
        can(abilityAction.READ, abilityComponent.NAV_MANAGE_PROFILE)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CREATOR)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_MANAGER)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CUSTOMER)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CLIENT)
        can(
          abilityAction.READ,
          abilityComponent.ORDER_DETAIL_INTERNAL_REVIEW_FILE
        )
        can(
          abilityAction.READ,
          abilityComponent.ANNOTATION_PUBLIC_INTERNAL_COMMENT
        )
        can(
          abilityAction.READ,
          abilityComponent.BTN_ORDER_DETAIL_ATTACH_INTERNAL_REVIEW
        )
        can(
          abilityAction.READ,
          abilityComponent.BTN_ORDER_DETAIL_ATTACH_CUSTOMER_REVIEW
        )
        can(abilityAction.READ, abilityComponent.NAV_PEXELS_ORDER_IMAGES)
        can(abilityAction.READ, abilityComponent.NAV_PAYG_CUSTOMER_INFO)
        can(abilityAction.READ, abilityComponent.PAYG_TIME)
        can(abilityAction.READ, abilityComponent.INVITE_GUEST_REVIEWER)

        // update
        can(abilityAction.UPDATE, abilityComponent.JOB_TITLE)
        can(abilityAction.UPDATE, abilityComponent.CHANGE_ORDER_STATUS)
        can(abilityAction.UPDATE, abilityComponent.ACTION_PLAY_WORKING_TIME)
        can(abilityAction.UPDATE, abilityComponent.ACTION_PAUSE_WORKING_TIME)
        can(abilityAction.UPDATE, abilityComponent.ACTION_HOLD_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_CANCEL_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_RESUME_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_RESTORE_ORDER)
        can(abilityAction.UPDATE, abilityComponent.REQUEST_CM_REVIEW)
        can(
          abilityAction.UPDATE,
          abilityComponent.ACTION_ORDER_DETAIL_DELETE_CUSTOMER_REVIEW_ATTACHMENT
        )

        // visit
        can(abilityAction.VISIT, abilityComponent.PAGE_MANAGE_PROFILE)

        break
      case userRole.MANAGER:
        // manager role
        can(abilityAction.READ, abilityComponent.ORDER_FILTER)
        can(abilityAction.READ, abilityComponent.ORDER_LIST_COMPANY_NAME)
        can(abilityAction.READ, abilityComponent.BTN_ADD_VERSION)
        can(abilityAction.READ, abilityComponent.ACTION_CM_APPROVE)
        can(abilityAction.READ, abilityComponent.NAV_MANAGE_PROFILE)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_OPERATOR)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CREATOR)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CUSTOMER)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CLIENT)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_MANAGER)
        can(
          abilityAction.READ,
          abilityComponent.ORDER_DETAIL_INTERNAL_REVIEW_FILE
        )
        can(
          abilityAction.READ,
          abilityComponent.ANNOTATION_PUBLIC_INTERNAL_COMMENT
        )
        can(
          abilityAction.READ,
          abilityComponent.BTN_ORDER_DETAIL_ATTACH_INTERNAL_REVIEW
        )
        can(
          abilityAction.READ,
          abilityComponent.BTN_ORDER_DETAIL_ATTACH_CUSTOMER_REVIEW
        )
        can(abilityAction.READ, abilityComponent.NAV_PEXELS_ORDER_IMAGES)
        can(abilityAction.READ, abilityComponent.NAV_PAYG_CUSTOMER_INFO)
        can(abilityAction.READ, abilityComponent.NAV_USER_REPORTING)
        can(abilityAction.READ, abilityComponent.PAYG_TIME)
        can(abilityAction.READ, abilityComponent.INVITE_GUEST_REVIEWER)

        // update
        can(abilityAction.UPDATE, abilityComponent.JOB_TITLE)
        can(abilityAction.UPDATE, abilityComponent.UPDATE_ORDER_DETAIL)
        can(abilityAction.UPDATE, abilityComponent.CHANGE_ORDER_STATUS)
        can(abilityAction.UPDATE, abilityComponent.ACTION_PLAY_WORKING_TIME)
        can(abilityAction.UPDATE, abilityComponent.ACTION_PAUSE_WORKING_TIME)
        can(abilityAction.UPDATE, abilityComponent.ACTION_HOLD_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_CANCEL_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_RESUME_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_RESTORE_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_ARCHIVE_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ASSIGNED_OPERATOR)
        can(
          abilityAction.UPDATE,
          abilityComponent.ACTION_ORDER_DETAIL_DELETE_ATTACHMENT
        )

        // visit
        can(abilityAction.VISIT, abilityComponent.PAGE_MANAGE_PROFILE)

        break
      case userRole.CREATIVE_MANAGER:
        // read
        can(abilityAction.READ, abilityComponent.ORDER_FILTER)
        can(abilityAction.READ, abilityComponent.ORDER_LIST_COMPANY_NAME)
        can(abilityAction.READ, abilityComponent.BTN_ADD_VERSION)
        can(abilityAction.READ, abilityComponent.ACTION_CM_APPROVE)
        can(abilityAction.READ, abilityComponent.NAV_MANAGE_PROFILE)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_OPERATOR)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CREATOR)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CUSTOMER)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CLIENT)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_MANAGER)
        can(
          abilityAction.READ,
          abilityComponent.ORDER_DETAIL_INTERNAL_REVIEW_FILE
        )
        can(
          abilityAction.READ,
          abilityComponent.ANNOTATION_PUBLIC_INTERNAL_COMMENT
        )
        can(
          abilityAction.READ,
          abilityComponent.BTN_ORDER_DETAIL_ATTACH_INTERNAL_REVIEW
        )
        can(
          abilityAction.READ,
          abilityComponent.BTN_ORDER_DETAIL_ATTACH_CUSTOMER_REVIEW
        )
        can(abilityAction.READ, abilityComponent.NAV_PEXELS_ORDER_IMAGES)
        can(abilityAction.READ, abilityComponent.NAV_PAYG_CUSTOMER_INFO)
        can(abilityAction.READ, abilityComponent.NAV_USER_REPORTING)
        can(abilityAction.READ, abilityComponent.PAYG_TIME)
        can(abilityAction.READ, abilityComponent.INVITE_GUEST_REVIEWER)

        // update
        can(abilityAction.UPDATE, abilityComponent.ACTION_CM_APPROVE)
        can(abilityAction.UPDATE, abilityComponent.JOB_TITLE)
        can(abilityAction.UPDATE, abilityComponent.UPDATE_ORDER_DETAIL)
        can(abilityAction.UPDATE, abilityComponent.CHANGE_ORDER_STATUS)
        can(abilityAction.UPDATE, abilityComponent.ACTION_PLAY_WORKING_TIME)
        can(abilityAction.UPDATE, abilityComponent.ACTION_PAUSE_WORKING_TIME)
        can(abilityAction.UPDATE, abilityComponent.ACTION_HOLD_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_CANCEL_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_RESUME_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_RESTORE_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_ARCHIVE_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ASSIGNED_OPERATOR)
        can(
          abilityAction.UPDATE,
          abilityComponent.ACTION_ORDER_DETAIL_DELETE_ATTACHMENT
        )

        // visit
        can(abilityAction.VISIT, abilityComponent.PAGE_MANAGE_PROFILE)
        break
      case userRole.PRODUCTION_MANAGER:
        // read
        can(abilityAction.READ, abilityComponent.ORDER_FILTER)
        can(abilityAction.READ, abilityComponent.ORDER_LIST_COMPANY_NAME)
        can(abilityAction.READ, abilityComponent.BTN_ADD_VERSION)
        can(abilityAction.READ, abilityComponent.ACTION_CM_APPROVE)
        can(abilityAction.READ, abilityComponent.NAV_MANAGE_PROFILE)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_OPERATOR)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CREATOR)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CUSTOMER)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CLIENT)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_MANAGER)
        can(
          abilityAction.READ,
          abilityComponent.ORDER_DETAIL_INTERNAL_REVIEW_FILE
        )
        can(
          abilityAction.READ,
          abilityComponent.ANNOTATION_PUBLIC_INTERNAL_COMMENT
        )
        can(
          abilityAction.READ,
          abilityComponent.BTN_ORDER_DETAIL_ATTACH_INTERNAL_REVIEW
        )
        can(
          abilityAction.READ,
          abilityComponent.BTN_ORDER_DETAIL_ATTACH_CUSTOMER_REVIEW
        )
        can(abilityAction.READ, abilityComponent.NAV_PEXELS_ORDER_IMAGES)
        can(abilityAction.READ, abilityComponent.NAV_PAYG_CUSTOMER_INFO)
        can(abilityAction.READ, abilityComponent.NAV_USER_REPORTING)
        can(abilityAction.READ, abilityComponent.PAYG_TIME)
        can(abilityAction.READ, abilityComponent.INVITE_GUEST_REVIEWER)

        // update
        can(abilityAction.UPDATE, abilityComponent.ACTION_CM_APPROVE)
        can(abilityAction.UPDATE, abilityComponent.JOB_TITLE)
        can(abilityAction.UPDATE, abilityComponent.UPDATE_ORDER_DETAIL)
        can(abilityAction.UPDATE, abilityComponent.CHANGE_ORDER_STATUS)
        can(abilityAction.UPDATE, abilityComponent.ACTION_PLAY_WORKING_TIME)
        can(abilityAction.UPDATE, abilityComponent.ACTION_PAUSE_WORKING_TIME)
        can(abilityAction.UPDATE, abilityComponent.ACTION_HOLD_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_CANCEL_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_RESUME_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_RESTORE_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_ARCHIVE_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ASSIGNED_OPERATOR)
        can(
          abilityAction.UPDATE,
          abilityComponent.ACTION_ORDER_DETAIL_DELETE_ATTACHMENT
        )

        // visit
        can(abilityAction.VISIT, abilityComponent.PAGE_MANAGE_PROFILE)
        break
      case userRole.CSO:
        // read
        can(abilityAction.READ, abilityComponent.ORDER_FILTER)
        can(abilityAction.READ, abilityComponent.ORDER_LIST_COMPANY_NAME)
        can(abilityAction.READ, abilityComponent.NAV_MANAGE_PROFILE)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_OPERATOR)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CREATOR)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CUSTOMER)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CLIENT)
        can(
          abilityAction.READ,
          abilityComponent.BTN_ORDER_DETAIL_ATTACH_CUSTOMER_REVIEW
        )
        can(abilityAction.READ, abilityComponent.NAV_PEXELS_ORDER_IMAGES)

        // visit
        can(abilityAction.VISIT, abilityComponent.PAGE_MANAGE_PROFILE)
        break
      case userRole.OWNER:
        // customer role

        //READ
        can(abilityAction.READ, abilityComponent.COMPANY_INFORMATION)
        can(abilityAction.READ, abilityComponent.MANAGE_ALLOCATED_HOURS)
        can(abilityAction.READ, abilityComponent.MANAGE_ACTIVE_ORDER)
        can(abilityAction.READ, abilityComponent.MANAGE_MEMBERSHIP)
        can(
          abilityAction.READ,
          abilityComponent.SUBSCRIPTION_INFORMATION_BILLING
        )
        can(abilityAction.READ, abilityComponent.MANAGE_STORAGE)
        can(abilityAction.READ, abilityComponent.MANAGE_SERVICE)
        // can(abilityAction.READ, abilityComponent.OPTIONS_BUY_SERVICE);
        // can(abilityAction.READ, abilityComponent.OPTIONS_UPGRADE_MEMBERSHIP);
        can(abilityAction.READ, abilityComponent.DROPDOWN_SERVICE_HAS_BUY_ONLY)
        can(abilityAction.READ, abilityComponent.CREATE_ORDER)
        can(abilityAction.READ, abilityComponent.INVITE_GUEST_REVIEWER)
        can(abilityAction.READ, abilityComponent.TOUR_GUIDE)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_CREATOR)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_OPERATOR)
        can(abilityAction.READ, abilityComponent.ORDER_FILTER_MANAGER)

        can(abilityAction.READ, abilityComponent.NAV_MANAGE_PROFILE)
        can(abilityAction.READ, abilityComponent.NAV_PAYMENT)
        can(abilityAction.READ, abilityComponent.NAV_TEAM_MEMBER)
        can(abilityAction.READ, abilityComponent.NAV_CART)
        can(abilityAction.READ, abilityComponent.NAV_MANAGE_ALLOCATED_HOURS)
        can(abilityAction.READ, abilityComponent.NAV_MANAGE_MEMBERSHIP)
        can(abilityAction.READ, abilityComponent.NAV_MANAGE_STORAGE)
        can(abilityAction.READ, abilityComponent.NAV_MANAGE_SERVICE)
        can(abilityAction.READ, abilityComponent.MEMBERSHIP_CUSTOMER)
        can(abilityAction.READ, abilityComponent.NAV_PEXELS_CUSTOMER_IMAGES)
        can(abilityAction.READ, abilityComponent.NAV_PEXELS_ADD_IMAGES)
        can(abilityAction.READ, abilityComponent.NAV_PEXELS_ORDER_IMAGES)
        can(abilityAction.READ, abilityComponent.PAYG_CART)

        //UPDATE
        can(abilityAction.UPDATE, abilityComponent.COMPANY_INFORMATION)
        can(abilityAction.UPDATE, abilityComponent.JOB_TITLE)
        can(abilityAction.UPDATE, abilityComponent.UPDATE_ORDER_DETAIL)
        can(abilityAction.UPDATE, abilityComponent.CHANGE_ORDER_STATUS)
        can(abilityAction.UPDATE, abilityComponent.ACTION_PUBLISH_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_CANCEL_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_DELETE_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_DUPLICATE_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_HOLD_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_RESUME_ORDER)
        can(abilityAction.UPDATE, abilityComponent.ACTION_REVERT_DRAFT)
        can(abilityAction.UPDATE, abilityComponent.ACTION_RESTORE_ORDER)
        can(
          abilityAction.UPDATE,
          abilityComponent.ACTION_ORDER_DETAIL_DELETE_ATTACHMENT
        )
        can(abilityAction.UPDATE, abilityComponent.SUBSCRIPTION_LEAVE)
        can(abilityAction.UPDATE, abilityComponent.NAV_PEXELS_CUSTOMER_IMAGES)
        can(abilityAction.UPDATE, abilityComponent.NAV_PEXELS_ADD_IMAGES)
        can(abilityAction.UPDATE, abilityComponent.NAV_PEXELS_ORDER_IMAGES)
        can(abilityAction.UPDATE, abilityComponent.GUEST_REVIEWER_CONTROL)

        //VISIT
        can(abilityAction.VISIT, abilityComponent.PAGE_INVOICE)
        can(abilityAction.VISIT, abilityComponent.PAGE_PAYMENT)
        can(abilityAction.VISIT, abilityComponent.PAGE_TEAM_MEMBER)
        can(abilityAction.VISIT, abilityComponent.PAGE_MANAGE_ALLOCATED_HOURS)
        can(abilityAction.VISIT, abilityComponent.PAGE_MANAGE_MEMBERSHIP)
        can(abilityAction.VISIT, abilityComponent.PAGE_MANAGE_STORAGE)
        can(abilityAction.VISIT, abilityComponent.PAGE_MANAGE_SERVICE)
        can(abilityAction.VISIT, abilityComponent.PAGE_CART)
        can(abilityAction.VISIT, abilityComponent.PAGE_CHECKOUT_INVOICE)
        can(abilityAction.VISIT, abilityComponent.PAGE_CHECKOUT)
        can(abilityAction.VISIT, abilityComponent.PAGE_CREATE_ORDER)
        can(abilityAction.VISIT, abilityComponent.PAGE_MANAGE_PROFILE)
        can(abilityAction.VISIT, abilityComponent.PAGE_WORKFLOW_EDITOR)

        // DELETE
        can(abilityAction.DELETE, abilityComponent.ACCOUNT)

        break
      default:
      // guest user (guest invitation annotation)
      // user doesn't have any permission
    }
  })
}
